<template>
  <v-container>
    <v-stepper
      id="workspace-stepper"
      v-model="e6"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
          dark
          color="color_green"
        >
          Ticketing configuration
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <v-form>
        <v-stepper-items>
          <v-stepper-content step="1">
            <ticketing-selects-table />
          </v-stepper-content>
        </v-stepper-items>
      </v-form>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  components: {
    TicketingSelectsTable: () => import('@/components/services/TicketingSelectsTable')
  },
  data() {
    return {
      e6: 1
    };
  },
  computed: {},
  watch: {
    $route() {}
  },
  methods: {}
};
</script>
